import classNames from 'classnames';
import React from 'react';
import styles from "./Loading.module.scss"

const Loading = ({inline}) => {
    return (
        <div className={classNames("w-100 text-center p-4", inline && "loading-inline")}>
            <div className={styles.ldsRipple}>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Loading;