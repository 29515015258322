import React, { useEffect, useState } from 'react';
import useScrollPosition from '../hooks/useScroll';
import {ArrowUpCircle} from 'react-bootstrap-icons'
import { 
  motion,
  useViewportScroll,
  useSpring,
  useTransform
 } from 'framer-motion'
 import smoothScroll from '../common/smoothscroll'

const ScrollToTop = () => {
  const [visible, setVisible] = useState(false)
  const { scrollYProgress } = useViewportScroll();
  const yRange = useTransform(scrollYProgress, [0, 1], [0, 1]);
  const pathLength = useSpring(yRange, { stiffness: 400, damping: 90 });
  const [isComplete, setIsComplete] = useState(false);

  useScrollPosition({
    effect: ({currPos}) => {
      if(currPos.y < -50){
        setVisible(true)
      }
      else{
        setVisible(false)
      }
    }
  })

  const onScrollTop = () => {
    smoothScroll.scrollTo()
  }

  useEffect(() => {
    yRange.onChange((v) => setIsComplete(v >= 1));
  }, [yRange]);
  
  return (
    visible 
      ? <div className="scroll-top-wrapper" onClick={onScrollTop}>
          <svg style={{position: "absolute", width: "57px", height: "57px", top: "2px", left: "2px"}} className="progress-icon" viewBox="-1 -1 60 60">
            <motion.path
              fill="none"
              strokeWidth="2"
              stroke="#124B63"
              strokeDasharray="0 1"
              d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
              style={{
                pathLength,
                rotate: 90,
                translateX: 0,
                translateY: 0,
                scaleX: -1 // Reverse direction of line animation
              }}
            />
          </svg>
          <motion.div>
            <ArrowUpCircle 
              className="scroll-top-btn"
              style={{fill: isComplete ? "#2498c9" : "#999999" }}
            /> 
          </motion.div>
        </div>
      : null
  );
};

export default ScrollToTop;