import classNames from 'classnames';
import React from 'react';
import styles from '../../styles/Home.module.scss'
import { motion } from 'framer-motion'
import { ChevronRight } from 'react-bootstrap-icons';
import TopBannerButton from './TopBannerButton'
import Bubble from '../Bubble/Bubble';

// mode: fill | undefined

const TopBanner = ({ title, description, actions, mode, children, style = {}, contentClass = "", customHeight, particle }) => {
  return (
    <div style={{ ...style }} className={classNames("w-100", styles.home, mode === "fill" && styles.full, customHeight && styles.customHeight)}>
      <div className={classNames(styles.animate_box, "animate_box")}>
        {particle}
      </div>
      <motion.div
        className={classNames(
          styles.circleBg, mode === "fill" && styles.fullFill, mode !== "fill" && "d-none d-md-block"
        )}
      // initial={{
      //   opacity: 0,
      //   scale: 0,
      // }}
      // animate={{
      //   opacity: 1,
      //   scale: 1
      // }}
      // transition={{
      //   duration: 0.5
      // }}
      ></motion.div>
      <div className="home-content">
        <div className="container">
          <div className="row">
            <div className={classNames("col-md-6 col-12", styles.homeContent, contentClass)}>
              <h1 className={styles.homeTitle} dangerouslySetInnerHTML={{ __html: title }}>
                {/* {title} */}
              </h1>
              <div className={styles.homeDesc}>
                {description}
              </div>
              {actions
                ? actions !== "none" 
                  ? actions(mode)
                  : null
                :
                <TopBannerButton
                  title="Get your report in 5 minutes"
                />
              }
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBanner;