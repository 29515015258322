import classNames from 'classnames';
import Link from 'next/link';
import React from 'react';
import { ChevronRight } from 'react-bootstrap-icons';
import styles from '../../styles/Home.module.scss'

const TopBannerButton = React.forwardRef(({title, icon, onClick, className, link, message = "", service_id=undefined} , ref) => {
  return (
    link ? 
      <Link 
        href={{
          pathname: link,
          query: {
            msg: message,
            service_id
          }
        }}
      >
        <div 
          ref={ref}
          onClick={onClick}
          className={classNames(
            "d-inline-flex align-items-center justify-content-between btn btn-outline-dark rounded-0 custom",
            styles.homeBtn,
            className
          )}
        >
          <p className="mb-0 text-left">
            {title}
          </p> 
          {icon ? icon : <ChevronRight className="ml-4" />}
        </div>
      </Link>
    : 
      <div 
        ref={ref}
        onClick={onClick}
        className={classNames(
          "d-inline-flex align-items-center justify-content-between btn btn-outline-dark rounded-0 custom",
          styles.homeBtn,
          className
        )}
      >
        <p className="mb-0 text-left">
          {title}
        </p> 
        {icon ? icon : <ChevronRight className="ml-4" />}
      </div>
  )
});

export default TopBannerButton;